// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { OrderPaymentMethod } from '@centrito/db'
import { type UserDataState } from './types'

const initialState: UserDataState = {
  userProfile: null,
  categoryIndex: 0,
  cart: {
    cart: {
      id: '',
      anonymousUserId: null,
      deliveryData: null,
      deliveryDate: null,
      deliveryType: null,
      discountData: null,
      paymentData: null,
      ongoingPaymentRequestId: null,
      validPaymentMethods: {
        methods: [
          OrderPaymentMethod.CASH,
          OrderPaymentMethod.PALOMMA__BANCOLOMBIA,
          OrderPaymentMethod.PALOMMA__CARD,
          OrderPaymentMethod.PALOMMA__PSE,
        ],
        reason: null,
      },
    },
    items: [],
    pricingData: {
      priceItemsDiscount: 0,
      priceItemsCost: 0,
      priceItemsTax: 0,
      priceItemsRevenue: 0,
      priceItemsSubtotal: 0,
      priceCartDiscount: 0,
      priceShippingRevenue: 0,
      priceCartSubtotal: 0,
      priceCartTotal: 0,
    },
  },
  selectedStore: null,
  checkout: {
    isActionEnabled: true,
    deliveryStepState: {
      isHighlighted: false,
      selectedType: null,
    },
    paymentStepState: {
      isHighlighted: false,
      selectedMethod: null,
      selectedBank: null,
      selectedBankName: null,
    },
    completeInformationState: {
      isHighlighted: false,
      isComplete: false,
      isSubmitting: false,
    },
  },
  orders: null,
  salesAssistant: {
    clientUserId: null,
  },
  appLastOpenedAt: null,
  searchedTerms: [],
  isScrollingToTop: false,
  anonymousUserId: null,
  sessionProductIds: [],
  isEmailNotificationsEnabled: false,
}

export default initialState
