import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { CartExtendedPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites/cart-extended.composite'
import type { UserIdentifier } from '@centrito/api/nest/shopper/cart/domain/types/user-identifier'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

const removeCoupon = createAsyncThunk<
  {
    updatedCart: CartExtendedPublicComposite
  },
  undefined,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/removeCoupon`, async (_payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const userId = currentState.auth.authenticatedData?.userId
  const anonymousId = currentState.userData.anonymousUserId
  const cartOwner = !isNil(userId)
    ? ({ type: 'AUTHENTICATED', userId } satisfies UserIdentifier)
    : ({ type: 'ANONYMOUS', anonymousId: anonymousId! } satisfies UserIdentifier)

  try {
    const resp = await trpcProxyClient.user.cart.removeCoupon.mutate(cartOwner)
    return {
      updatedCart: resp.cart,
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(miniSerializeError(e))
  }
})
export default removeCoupon
